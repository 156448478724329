import React from "react"
import "../scss/index.scss"
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Hero from "../components/hero/hero"
import Double from "../components/layout/double"
import Quad from "../components/layout/quad"
import Footer from "../components/layout/footer"
import Icon5 from "../../static/icons/eco.png"
import Icon6 from "../../static/icons/ph.png"
import Icon7 from "../../static/icons/dog.png"
import Icon8 from "../../static/icons/biodegradable.png"
import { Helmet } from "react-helmet"
import TeamPoster from "../../static/img/_DSC0562.jpg"

export default function Home() {
  return <>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Eco Wash Solutions | Anywhere Anytime We Come To You</title>
    <description>Auckland wide there are millions of vehicles on the roads every day. They are getting dirty with daily driven. It is very important to clean and maintain them because this is your investment. Every vehicle should clean once a week or every fortnightly or must be every four weeks time. Also upholstery and carpet need to clean</description>
    <link rel="canonical" href="https://ecocarwash.co.nz" />
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0" nonce="g9L9nfuY"></script>
  </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <Hero banner={TeamPoster} pretitle="Modern Edquipment" title="Eco Wash" description="Anywhere Anytime We Come to You" />
  {/* Sites Linking */}
   {/* Intro Block */}
      {/* Icons / Services */}

   <Double align="left" type="light">
   <div className="double-block two-third" id="about1">
   <h2 className="heading align--center">About</h2>
    <h6 className="sub-heading sub-heading--basic align--center">What we do..</h6>
      {/* <h6 className="sub-heading sub-heading--basic">Eco Wash Solutions proudly introduces</h6> */}
      <p>Eco Wash Solutions, formerly known as Eco Car Wash, was established in 2013 with a mission to provide excellent services to customers throughout Auckland. As pre-eminent mobile car groomers in the region, the company has become a well-respected name in the industry, thanks to its commitment to delivering superior customer experience and tremendous value to its customers.<br/><br/>
      The company has amassed a wealth of experience in the mobile car wash service, utilizing top-of-the-line equipment and tools that adhere to industry standards. Over the years, Eco Wash Solutions has expanded its services to cater to the needs of its customers, including the truck wash industry and property wash solutions. This has enabled the company to expand its reach and diversify its offerings.<br/><br/>
      Eco Wash Solutions is dedicated to providing its customers with high-quality services that exceed expectations. The company's team of skilled professionals is committed to delivering personalized service and attention to detail, ensuring that every customer is satisfied with the work done. By utilizing the latest technologies, tools, and techniques, the company is able to deliver efficient, effective, and eco-friendly solutions that meet the needs of its customers.<br/><br/>
      As the company continues to expand, Eco Wash Solutions remains committed to its core values of delivering high-quality service, environmental responsibility, and exceptional value to its customers. The company aims to establish itself as a leader in the car wash, truck wash and property wash industries by providing innovative, reliable, and cost-effective solutions that meet the unique needs of its customers.
      </p>
      </div>
      {/* <div className="double-block on-third">
        <img src={Image1} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" className="ratings-image" width="100%" />
      </div> */}
   </Double>   

    {/* About Block */}
    {/* <div className="pricing-block container--dark container--centered" id="about">
    <div className="container container--narrow">
    <h2 className="heading align--center">About</h2>
    <h6 className="sub-heading sub-heading--basic align--center">What we do..</h6>
    <p>Eco Wash Solutions has been delivering results since its opened in 2013. Our goal is to provide both a superior customer service experience and tremendous value for our customers.</p>
    <p>Eco Wash Solutions has good experience in Mobile Car Wash Service and we are the best mobile car groomer in Auckland. Our valued customers provide us 5star review on our Facebook page. We are also passionate about exceeding customers expectations.</p>
    <p>Eco Wash Solutions and Detail services from Daily driven vehicle, Luxury , Luxury sports , Classic car to Caravans, Boats and Motorcycles</p>
    <p>Eco Wash Solutions and Detail provide skilled and experienced car valet services for your car. We can easily fit in your schedule, wherever you are, in office or home.</p>
    <p>Eco Wash Solutions has highly competitive prices list below . You can choose one of them or if you not sure about any detail we are happy to assist you over the phone or fill up simple contact form below and we will get in touch.</p>
    </div>
    </div> */}

   <hr />
   <div className="container">
   </div>
   <Quad align="center" id="eco-block" className="align--center">
    <div className="block-item a">
      <img className="icon-medium" src={Icon5} alt="Car Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Eco</span> Friendly</h4>
    </div>
    <div className="block-item">
      <img className="icon-medium" src={Icon6} alt="Motorcycle Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>PH</span> Neutral</h4>
  </div>
  <div className="block-item">
       <img className="icon-medium" src={Icon7} alt="Boat Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Pet</span> Safe</h4>
  </div>
  <div className="block-item">
      <img className="icon-medium" src={Icon8} alt="Caravan / Motorhome Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Biodegradable</span></h4>
  </div>
   </Quad>
   <hr />

   {/* <div className="imageBg-block container--centered" id="gift">
     <div className="container">
     <h2 className="heading align--center">Give the gift of a sparkling car grooming</h2>
     <br />
     <h6 className="sub-heading sub-heading--basic align--center">What our clients say...</h6>
     </div>
  </div>  */}

  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2023 Eco Wash Solutions. All Rights Reserved" />  
  </>
}
