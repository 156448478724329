import React from "react"

export default function Double(props) {
  return <> 
  <div className={`double-container ${props.align && 'double-container--${props.align}'} double-container--${props.type} ${props.reverseOnDesktop && 'double-container--reverseOnDesktop'}`} id={props.id}>
    <div className="container">
        {props.children}
    </div>
  </div>
  </>
}